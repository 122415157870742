export default class Response {
    constructor(data) {
        // invokes the setter
        const jsonMessage = JSON.parse(data);
        this.responseInfo = jsonMessage['responseInfo'];
        this.responseData = jsonMessage['responseData'];

        this.status = jsonMessage['status'];
        this.message = jsonMessage['message'];    
    }
    
    get status() {
      return this._status;
    }
    
    set status(value){
        this._status = value;
    }
    
    get message(){
        return this._message;
    }

    set message(value){
        this._message = value; 
    }
    
    get responseInfo(){
        return this._responseInfo;
    }
    
    set responseInfo(value){
        this._responseInfo = value;
    }
    
    get responseData(){
        return this._responseData;
    }

    set responseData(value){
        this._responseData = value;
    }
    
    getActionId(){
        return this._responseInfo['requestActionId'];
    }

    getMessage(){
        return this._message;
    }
    getTagValue(name){
        return this._responseInfo['tag'][name];
    }

    getResponseData(){
        return this._responseData;
    }
    getResponseDataValue(name){
        return this._responseData[name];
    }
    
    getResponseInfoValue(name){
        return this._responseInfo[name];
    }
    
    getResponseCode(){
        return this._responseInfo['responseCode'];
    }
    
    isSuccess(){
        return this._status === 'SUCCESS';
    }
    
    isEmptyResponseData(){
        if (!this._responseData){
            return true;
        }
        return false;
    }
    
    set name(value) {
        if (value.length < 4) {
            alert("Name is too short.");
            return;
        }
        this._name = value;
    }

}

